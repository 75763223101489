import React, { useEffect, useState } from "react";

const TestimonialSection = () => {
  const [testimonial, setTestimonial] = useState([]);

  const [testimonialtext, setTestimonialText] = useState([]);

  useEffect(() => {
    fetch(`https://lead1cone-48a5181a241e.herokuapp.com/testimonialtext`)
      .then((res) => res.json())
      .then((info) => setTestimonialText(info));
  }, []);

  useEffect(() => {
    fetch(`https://lead1cone-48a5181a241e.herokuapp.com/testimonials`)
      .then((res) => res.json())
      .then((info) => setTestimonial(info));
  }, []);


  const divStyle = {
    backgroundImage: "url(img/testimonial/test-bg-aliments.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "#fff7f5",
  };

  return (
    <>
      <section className="web_testimonial-section" id="testimonial-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 col-12 order-md-1 order-2">
              <div className="testimonial_content">

                {
                  testimonialtext.map(t => <div className="heading">
                    <h5>{t.testimonialText}</h5>
                    <h2>{t.testimonialHeading}</h2>
                  </div>)
                }

                <div id="webtestimonialcarousel" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-inner">

                    {
                      testimonial.map(c=><div className="carousel-item active">
                      <div className="testimonial-content position-relative">
                        <div className="box-content">
                          <p className="text"><span>“</span>{c.desc}<span>”</span>
                          </p>
                          <div className="content">
                            <figure className="testimonial-contentimage mb-0">
                              <img src={c.image} alt className="img-fluid" />
                            </figure>
                            <div className="designation-outer">
                              <span>{c.personName}</span>
                              <p className="text-size-16 mb-0">{c.personTitle}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>)
                    }

                    
                    
                  </div>
                  <div className="pagination-outer">
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-12 order-md-2 order-1 text-md-left text-center">
              <div className="testimonial_wrapper position-relative">
                <figure className="testimonial-image mb-0">
                  <img src="https://i.ibb.co/FxrzBcm/testimonial-image.jpg" className="img-fluid" alt />
                </figure>
                <figure className="testimonial-boximage1 mb-0">
                  <img src="./assets/images/contact-boximage1.png" className="img-fluid" alt />
                </figure>
                <figure className="testimonial-boximage2 mb-0">
                  <img src="./assets/images/testimonial-boximage2.png" className="img-fluid" alt />
                </figure>
                <figure className="testimonial-boximage3 mb-0">
                  <img src="./assets/images/contact-boximage3.png" className="img-fluid" alt />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>




    </>
  );
};

export default TestimonialSection;
