// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCHp1j-c1bD0PPpWbCbnxTPrRdB3aSzwm0",
  authDomain: "verynewleadoneclone.firebaseapp.com",
  projectId: "verynewleadoneclone",
  storageBucket: "verynewleadoneclone.appspot.com",
  messagingSenderId: "699932462319",
  appId: "1:699932462319:web:467ec068269c1c624fdf1a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;