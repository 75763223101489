import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {

  const navigate = useNavigate();
  const [footerAddress, setFooterAddress] = useState([]);

  useEffect(() => {
    fetch(`https://lead1cone-48a5181a241e.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);





  const handleMessage = (event) => {
    event.preventDefault();
    const fullName = event.target.fullName.value;
    const emailAddress = event.target.emailAddress.value;
    const phone = event.target.phone.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;
    const messageStatus = event.target.messageStatus.value;


    const addItem = {
      fullName,
      emailAddress,
      phone,
      subject,
      message,
      messageStatus,

    };

    const url = `https://lead1cone-48a5181a241e.herokuapp.com/add-message`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {
        
        alert('Message is Updated');
      });
  };

  const [action, setAction] = useState([]);

  useEffect(() => {
    fetch(`https://lead1cone-48a5181a241e.herokuapp.com/actions`)
      .then((res) => res.json())
      .then((info) => setAction(info));
  }, []);



  return (



    // <>
    //   <section className="contact-area overflow-hidden py-130 bgc-black-with-lighting rel z-1" id='contact'>
    //     <div className="container">
    //       <div className="row justify-content-between">
    //         <div className="col-xl-5 col-lg-6">
    //           {
    //             footerAddress.map(a=> <div className="contact-info-area text-white rmb-75 wow fadeInLeft delay-0-2s">
    //             <div className="section-title mb-55">
    //               <h2>Have any project on mind! feel free contact with us or <span>say hello</span></h2>
    //             </div>
    //             <div className="contact-info-wrap">
    //               <div className="contact-info-item">
    //                 <div className="icon">
    //                   <i className="fal fa-map-marker-alt" />
    //                 </div>
    //                 <div className="content">
    //                   <span className="title">Location</span>
    //                   <b className="text">{a.footeraddress}</b>
    //                 </div>
    //               </div>
    //               <div className="contact-info-item">
    //                 <div className="icon">
    //                   <i className="far fa-envelope-open-text" />
    //                 </div>
    //                 <div className="content">
    //                   <span className="title">Email Address</span>
    //                   <b className="text">{a.footerEmail}</b>
    //                 </div>
    //               </div>
    //               <div className="contact-info-item">
    //                 <div className="icon">
    //                   <i className="far fa-phone" />
    //                 </div>
    //                 <div className="content">
    //                   <span className="title">Phone No</span>
    //                   <b className="text">{a.footerPhone}</b>
    //                 </div>
    //               </div>
    //             </div>
    //           </div> )
    //           }
             
    //         </div>
    //         <div className="col-xl-5 col-lg-6">
    //           <form onSubmit={handleMessage} id="contactForm mt-100">
    //           <h2 className=' text-white mb-50'>Send us Message</h2>

    //             <div className="row">
    //               <div className="col-lg-6 col-md-6">
                    
    //                 <div className="form-group">
    //                   <input type="text" name="name" className="form-control" id="fullName" required data-error="Please enter your name" placeholder="Name" />
    //                   <div className="help-block with-errors" />
    //                 </div>
    //               </div>
    //               <div className="col-lg-6 col-md-6">
    //                 <div className="form-group">
    //                   <input type="email" name="email" className="form-control" id="emailAddress" required data-error="Please enter your email" placeholder="Email" />
    //                   <div className="help-block with-errors" />
    //                 </div>
    //               </div>
    //               <div className="col-lg-12 col-md-12">
    //                 <div className="form-group">
    //                   <input type="text" name="subject" className="form-control" id="subject" required data-error="Please enter your phone number" placeholder="Subject" />
    //                   <div className="help-block with-errors" />
    //                 </div>
    //               </div>
    //               <div className="col-lg-12 col-md-12">
    //                 <div className="form-group">
    //                   <input type="text" name="messageStatus" className="form-control" hidden value='UnRead' />
    //                   <div className="help-block with-errors" />
    //                 </div>
    //               </div>
    //               <div className="col-lg-12 col-md-12">
    //                 <div className="form-group">
    //                   <textarea name="message" id="message" className="form-control" cols={30} rows={6} required data-error="Please enter your message" placeholder="Message..." defaultValue={""} />
    //                   <div className="help-block with-errors" />
    //                 </div>
    //               </div>
    //               <div className="col-lg-12 col-md-12">
    //                 <div className="form-group">

    //                 </div>
    //               </div>
    //               <div className="col-lg-12 col-md-12">
    //                 <button type="submit" class="btn-start-btn">Send messages <i class="fas fa-angle-double-right"></i></button>
    //                 <div id="msgSubmit" className="h5 text-center hidden" />
    //               </div>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="contact-shapes">
    //       <img className="shape circle" src="assets/images/shapes/slider-dots.png" alt="Shape" />
    //       <img className="shape dots" src="assets/images/shapes/contact-dots.png" alt="Shape" />
    //       <img className="shape wave-line" src="assets/images/shapes/contact-wave-line.png" alt="Shape" />
    //     </div>
    //   </section>


    // </>

    <>

<section className="web_contact-section" id='contact-sec'>
  <div className="container"> 
    <div className="row">
      <div className="col-lg-6 col-md-12 col-sm-12 col-12 text-md-left text-center">
        <div className="contact_wrapper position-relative">
          <figure className="contact-image mb-0">
            <img src="https://i.ibb.co/h9nk7r1/contact-image-1.jpg" className="img-fluid" alt />
          </figure>
          <figure className="contact-boximage1 mb-0">
            <img src="./assets/images/contact-boximage1.png" className="img-fluid" alt />
          </figure>
          <figure className="contact-boximage2 mb-0">
            <img src="./assets/images/contact-boximage2.png" className="img-fluid" alt />
          </figure>
          <figure className="contact-boximage3 mb-0">
            <img src="./assets/images/contact-boximage3.png" className="img-fluid" alt />
          </figure>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="contact_content">
          <div className="heading">
            <h5>Get in Touch</h5>
            <h2>Send us a Message</h2>
          </div>
          <form onSubmit={handleMessage} id="contactpage" method="POST" action="./contact-form.php">
            <div className="form-group"> 
              <input type="text" className="form_style input1" placeholder="Name" name="fullName" /> 
            </div>
            <div className="form-group">
              <input type="tel" className="form_style" placeholder="Phone" name="phone" />
            </div>
            <div className="form-group">
              <input type="email" className="form_style input1" placeholder="Email" name="emailAddress" />
            </div>
            <div className="form-group"> 
              <input type="text" className="form_style" placeholder="Your Subject" name="subject" />
            </div>
            <div className="form-group"> 
            <input type="text" name="messageStatus" className="form-control" hidden value='UnRead' />
            </div>
            <div className="form-group message">
              <textarea className="form_style" placeholder="Message" rows={3} name="message" defaultValue={""} />
            </div>
            <button type="submit" className="send_message">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

    
    
    </>
  );
};

export default ContactUs;