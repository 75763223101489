import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ServicesSection = () => {


  const [items, setItems] = useState([]);
  const [service, setService] = useState([]);

  useEffect(() => {
    fetch(`https://lead1cone-48a5181a241e.herokuapp.com/service-items`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);


  useEffect(() => {
    fetch(`https://lead1cone-48a5181a241e.herokuapp.com/service`)
      .then((res) => res.json())
      .then((info) => setService(info));
  }, []);


  return (

    <>

<section className="web_service-section" id="service-sec">
  <div className="container">
    <div className="upper-text">
      <div className="row">
        
        <div className="col-lg-7 col-md-12 col-sm-12 col-12">
          {
            service.map(e=> <div className="heading">
            <h5>{e.serviceHeading}</h5>
            <h2 className="mb-0">{e.servicesubHeading}</h2>
          </div>)
          }
          
          
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12 col-12">
          <div className="text">
           
          </div>
        </div>
      </div>
    </div>
    <div className="image-row" >
      <div className="row">

        {
          items.map(item=> <div className="col-lg-4 col-md-6 col-sm-6 col-12">
          <div className="service-box">
            <span><img src={item.serviceIcon} alt="icon_shape" /></span>
            <h4>{item.serviceTitle}</h4>
            <p className="text-size-18">{item.serviceDetails}</p>
            
          </div>
        </div>
        )
        }
        
        
       
        
      </div>
    </div>
  </div>
</section>







    </>


  );
};

export default ServicesSection;
