import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';


const AddWhyChooseText = () => {

    const [choosetext, setService] = useState([]);
    const { id } = useParams();


    useEffect(() => {
        fetch(`https://lead1cone-48a5181a241e.herokuapp.com/choose/${id}`)
            .then((res) => res.json())
            .then((info) => setService(info));
    }, []);




    const handleChooseText = (event) => {
        event.preventDefault();
        const choosesubHeading = event.target.choosesubHeading.value;
        const chooseHeading = event.target.chooseHeading.value;
        const chooseImg = event.target.chooseImg.value;
      

        const chooseSection = {
            choosesubHeading,
            chooseHeading,
            chooseImg,
            

        };

        const url = `https://lead1cone-48a5181a241e.herokuapp.com/choose/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(chooseSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert(' Choose Text is Updated');
            });
    };




    return (
        <>
        
            {
                <section id="services" class="services-area mt-5 mb-5 fix" >
                    <div class="container">
                        <div class="row">

                        </div>
                        <div class="row">

                            <div class="col-lg-8 col-md-12 ">
                                <h3> Update Service Text </h3>

                                <form class="contact-form " onSubmit={handleChooseText}>
                                    <div class="row">

                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="chooseHeading" defaultValue={choosetext.chooseHeading}  placeholder="Choose Heading" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="choosesubHeading" defaultValue={choosetext.choosesubHeading}  placeholder="Choose Text" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="chooseImg" defaultValue={choosetext.chooseImg}  placeholder="Choose Image" required />
                                            </div>
                                        </div>
                                         <div class="slider-btn">
                                            <button class="btn-start-btn" data-animation="fadeInRight" data-delay=".8s"> Update Choose Text</button>
                                        </div>
                                    </div>

                                </form>



                            </div>



                        </div>
                    </div>

                </section>
            }
        </>
    );
};

export default AddWhyChooseText;