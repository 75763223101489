import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const UpdateAboutSection = () => {

    const [about, setAbout] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://lead1cone-48a5181a241e.herokuapp.com/about-us/${id}`)
            .then((res) => res.json())
            .then((info) => setAbout(info));
    }, [id]);



    const handleAboutSection = (event) => {
        event.preventDefault();

        const aboutHeading = event.target.aboutHeading.value;
        const aboutImg = event.target.aboutImg.value;
        const aboutText = event.target.aboutText.value;
        const aboutDetails = event.target.aboutDetails.value;
        const aboutPointOne = event.target.aboutPointOne.value;
        const aboutPointTwo = event.target.aboutPointTwo.value;
        const aboutPointThree = event.target.aboutPointThree.value;
        const aboutPointFour = event.target.aboutPointFour.value;
        



        const aboutSection = {

            aboutImg,
            aboutText,
            aboutHeading,
            aboutDetails,
            aboutPointOne,
            aboutPointTwo,
            aboutPointThree,
            aboutPointFour,
          


        };

        const url = `https://lead1cone-48a5181a241e.herokuapp.com/update-about/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(aboutSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert('About is Updated');
            });
    };



    return (
        <>
        

            <section id="services" class="services-area mt-5 mb-5 fix" >
                <div class="container">
                    <div class="row">

                    </div>
                    <div class="row">

                        <div class="col-lg-8 col-md-12">
                            <h3> Update About </h3>



                            <form class="contact-form " onSubmit={handleAboutSection}>
                                <div class="row">

                                    <div className="col-lg-12 col-md-12 mb-3 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutImg" defaultValue={about.aboutImg} placeholder="About Image" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutHeading" defaultValue={about.aboutHeading} placeholder="About Heading" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutText" defaultValue={about.aboutText} placeholder="About Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutDetails" defaultValue={about.aboutDetails} placeholder="About Details" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutPointOne" defaultValue={about.aboutPointOne} placeholder="Point One" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutPointTwo" defaultValue={about.aboutPointTwo} placeholder="Point Two" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutPointThree" defaultValue={about.aboutPointThree} placeholder="Point Three" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutPointFour" defaultValue={about.aboutPointFour} placeholder="Point Four" required />
                                        </div>
                                    </div>
                                   



                                    <div class="slider-btn">
                                        <button class="btn-start-btn" data-animation="fadeInRight" data-delay=".8s"> Update About </button>
                                    </div>
                                </div>

                            </form>



                        </div>


                    </div>
                </div>
            </section>
        </>
    );
};

export default UpdateAboutSection;