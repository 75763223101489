import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";

const Footer = () => {






  const [footerSocial, setFooterSocial] = useState([]);
  const [footerCopyright, setFooterCopyright] = useState([]);

  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://lead1cone-48a5181a241e.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);



  const [footerAddress, setFooterAddress] = useState([]);



  useEffect(() => {
    fetch(`https://lead1cone-48a5181a241e.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);

  useEffect(() => {
    fetch(`https://lead1cone-48a5181a241e.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setFooterSocial(info));
  }, []);

  useEffect(() => {
    fetch(`https://lead1cone-48a5181a241e.herokuapp.com/copyrights`)
      .then((res) => res.json())
      .then((info) => setFooterCopyright(info));
  }, [footerCopyright]);


  const handleSubscriber = (event) => {
    event.preventDefault();
    const email = event.target.email.value;




    const addItem = {
      email,



    };

    const url = `https://lead1cone-48a5181a241e.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Subscribers is Updated');
      });


  }










  return (



    <>

      <div>
        <div className="web_traffic_footer">
          <div className="footer-section">
            <div className="container">
              <div className="middle-portion">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-6 col-12">
                    {
                      logo.map(l => <a href="/">
                        <figure className="footer-logo">
                          <img src={l.logo} className="img-fluid" alt />
                        </figure>
                      </a>)
                    }

                    {
                      footerSocial.map(s => <ul className="list-unstyled mb-0 social-icons">
                        <p className="text-size-16 footer-text">{s.aboutText}</p>
                        <li><a href={s.fblink} className="text-decoration-none"><i className="fa-brands fa-facebook-f social-networks" /></a></li>
                        <li><a href={s.inslink} className="text-decoration-none"><i className="fa-brands fa-instagram social-networks" /></a></li>

                      </ul>)
                    }



                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-12 col-12 d-md-block d-none">
                    <div className="links">
                      <ul className="list-unstyled mb-0">
                        <li><i className="fa-solid fa-square-full" /><a href="/" className=" text-size-16 text text-decoration-none">Home</a></li>
                        <li><i className="fa-solid fa-square-full" /><a href="#about-sec" className=" text-size-16 text text-decoration-none">About</a></li>
                        <li><i className="fa-solid fa-square-full" /><a href="#service-sec" className=" text-size-16 text text-decoration-none">Services</a></li>
                        <li><i className="fa-solid fa-square-full" /><a href="#pricing-sec" className=" text-size-16 text text-decoration-none">Pricing</a></li>
                        <li><i className="fa-solid fa-square-full" /><a href="#contact-sec" className=" text-size-16 text text-decoration-none">Contact us</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-sm-block">
                    <div className="icon">
                      {
                        footerAddress.map(a => <ul className="list-unstyled mb-0">
                          <li className="text">
                            <i className="fa fa-phone fa-icon footer-location" />
                            <a className="mb-0 text text-decoration-none text-size-16">{a.footerPhone}</a>
                          </li>
                          <li className="text">
                            <i className="fa fa-envelope fa-icon footer-location" />
                            <a className="mb-0 text text-decoration-none text-size-16">{a.footerEmail}</a>
                          </li>
                          <li className="text">
                            <i className="fa-solid fa-location-dot footer-location footer-location3" />
                            <p className="text1 text-size-16">{a.footeraddress}</p>
                          </li>
                        </ul>)
                      }

                    </div>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12 col-12 d-lg-block d-none">
                    <div className="email-form">
                      <form onSubmit={handleSubscriber} action="javascript:;">
                        <div className="form-group position-relative">
                          <input type="text" className="form_style" placeholder="Enter Email:" name="email" />
                          <button><i className="send fa-sharp fa-solid fa-paper-plane" /></button>
                        </div>
                        <div className="form-group check-box">
                          <input type="checkbox" id="term" />
                          <label htmlFor="term">Now Subscribe for Newsletter</label>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="copyright">
                <div className="row">
                  {
                    footerCopyright.map(c => <div className="col-12">
                      <p className="mb-0 text-size-16">{c.copyrightText}</p>
                    </div>)
                  }

                </div>
              </div>
            </div>
          </div>
        </div>


      </div>



    </>
  );
};

export default Footer;
